import React, {cloneElement} from 'react';
import {ExportButton, sanitizeListRestProps, TopToolbar, useListContext, downloadCSV } from "react-admin";
import jsonExport from 'jsonexport/dist';


const OrderListActions = (props) => {
    const {
        filters,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();

    const exporter = posts => {
        jsonExport(posts, {rowDelimiter: ';'}, (err, csv) => {
            downloadCSV(csv, 'export');
        });
    };

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}

            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={5000}
                exporter={exporter}
            />
        </TopToolbar>
    );
}

export default OrderListActions;

import {useLocale, useSetLocale} from 'react-admin';
import {MenuItem, Select} from '@material-ui/core';
import {GetAllowedLanguages} from "../../Providers/i18nProvider"

const LocaleSwitcher = () => {
    const setLocale = useSetLocale();
    const locale = useLocale();

    const handleChange = (event) => {
        setLocale(event.target.value);
    };

    let languages = GetAllowedLanguages();

    return (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={locale}
            onChange={handleChange}
        >
            {
                Object.keys(languages).map((lang) => {
                    return (
                        <MenuItem key={lang} value={lang}>{lang}</MenuItem>
                    )
                })
            }
        </Select>
    );
};

export default LocaleSwitcher;

import {Box} from "@material-ui/core";
import * as React from "react";

const Loading = () => {
    return (
        <Box display="flex" alignItems="flex-end" mb={1}>
            <Box component="span" mr={8}>
                <p>{'ra.page.loading'}</p>
            </Box>
            <Box component="span" mr={2}>

            </Box>
        </Box>
    )
}

export default Loading;

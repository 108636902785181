import {DateTimeInput, FilterList, FilterListItem, useListContext} from "react-admin";
import {endOfYesterday, startOfMonth, startOfWeek, subMonths, subWeeks} from "date-fns";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {Box} from "@material-ui/core";
import {Form} from "react-final-form";
import * as React from "react";

const LastVisitedFilter = (props) => {
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter,
    } = useListContext();

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        } else {
            hideFilter("main");
        }
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    const DateFilterValues = {
        today: {}, thisWeek: {}, lastWeek: {}, thisMonth: {}, lastMonth: {}
    }

    DateFilterValues.today[`${props.source}[after]`] = endOfYesterday().toISOString()
    DateFilterValues.today[`${props.source}[before]`] = undefined

    DateFilterValues.thisWeek[`${props.source}[after]`] = startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString()
    DateFilterValues.thisWeek[`${props.source}[before]`] = undefined

    DateFilterValues.lastWeek[`${props.source}[after]`] = subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1).toISOString()
    DateFilterValues.lastWeek[`${props.source}[before]`] = startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString()

    DateFilterValues.thisMonth[`${props.source}[after]`] = startOfMonth(new Date()).toISOString()
    DateFilterValues.thisMonth[`${props.source}[before]`] = undefined

    DateFilterValues.lastMonth[`${props.source}[after]`] = subMonths(startOfMonth(new Date()), 1).toISOString()
    DateFilterValues.lastMonth[`${props.source}[before]`] = startOfMonth(new Date()).toISOString()


    return (
        <div>
            <FilterList label="ra.field.createdAt" icon={<AccessTimeIcon/>}>
                <FilterListItem
                    label="ra.date.today"
                    value={DateFilterValues.today}
                />
                <FilterListItem
                    label="ra.date.this_week"
                    value={DateFilterValues.thisWeek}
                />
                <FilterListItem
                    label="ra.date.last_week"
                    value={DateFilterValues.lastWeek}
                />
                <FilterListItem
                    label="ra.date.this_month"
                    value={DateFilterValues.thisMonth}
                />
                <FilterListItem
                    label="ra.date.last_month"
                    value={DateFilterValues.lastMonth}
                />
            </FilterList>

            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <DateTimeInput source={`${props.source}[after]`} onChange={handleSubmit}
                                           label={'ra.field.from'}/>
                        </Box>
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <DateTimeInput source={`${props.source}[before]`} onChange={handleSubmit}
                                           label={'ra.field.to'}/>
                        </Box>
                    </form>
                )}
            </Form>
        </div>
    )
}

export default LastVisitedFilter

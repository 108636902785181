import {ListButton, SaveButton, Toolbar} from 'react-admin'

const CreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="ra.button.save_and_show"
            redirect="show"
            submitOnEnter={true}
        />
        <SaveButton
            label="ra.button.save_and_add"
            redirect={false}
            submitOnEnter={false}
        />
        <ListButton icon="" label='ra.field.cancel'/>
    </Toolbar>
);

export default CreateToolbar

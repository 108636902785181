import React from 'react';
import {EditButton, ListButton, TopToolbar} from "react-admin";


const ShowActions = ({basePath, data}) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data}/>
        <ListButton/>
    </TopToolbar>
);

export default ShowActions

import {
    BooleanField,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import DateTimeField from "../Components/Fields/DateTimeField";
import ListActions from "../Components/Toolbars/TopToolbars/ListActions";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import Aside from "../Components/Aside";
import CompanyInput from "../Components/Inputs/CompanyInput";
import CompanyField from "../Components/Fields/CompanyField";
import {getUser} from '../Providers/UserProvider';

const Title = ({record}) => {
    return <span>{record ? `${record.name}` : ''}</span>;
};

export const TerminalList = (props) => (
    <List
        title="ra.page.terminals"
        sort={{ field: 'updatedAt', order: 'DESC' }}
        aside={<Aside filters={{
            id: 'live',
            name: 'live',
            address: 'live',
            active: 'boolean',
            createdAt: 'dateRange'
        }}/>} {...props} actions={<ListActions/>} >
        <Datagrid rowClick="edit">

            <NumberField label="#" source="originId" sortBy={'id'}/>

            <TextField label="ra.field.name" source={'name'}/>
            <TextField label="ra.field.address" source={'address'}/>

            <BooleanField label="ra.field.active" source={'active'}/>

            <TextField label="ra.field.currency" source={'currency'}/>

            <CompanyField label="ra.field.company"/>

            <ReferenceField label="ra.field.serviceSet" reference={'service_sets'} source={'serviceSet'}>
                <ChipField source={'id'}/>
            </ReferenceField>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

            <ShowButton/>
            <DeleteButton/>

        </Datagrid>
    </List>
);

export const TerminalShow = (props) => (
    <Show actions={<ShowActions/>} title={<Title/>} {...props}>
        <SimpleShowLayout>

            <TextField label="ra.field.name" source={'name'}/>
            <TextField label="ra.field.address" source={'address'}/>

            <TextField label="ra.field.softwareVersion" source={'softwareVersion'}/>

            <NumberField label="ra.field.pin" source={'pin'}/>

            <BooleanField label="ra.field.active" source={'active'}/>

            <TextField label="ra.field.currency" source={'currency'}/>

            <TextField label="ra.field.company"/>
            <CompanyField/>

            <ReferenceField reference={'service_sets'} source={'serviceSet'}>
                <ChipField source={'id'}/>
            </ReferenceField>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

        </SimpleShowLayout>
    </Show>
);

export const TerminalEdit = (props) => (
    <Edit actions={<EditActions/>} title={<Title/>} {...props}>
        <SimpleForm>

            <TextInput label="ra.field.name" source="name"/>
            <TextInput label="ra.field.address" source="address"/>

            <TextInput label="ra.field.softwareVersion" source="softwareVersion"/>

            <NumberInput label="ra.field.pin" source={'pin'}/>

            <BooleanInput label="ra.field.active" source={'active'}/>

            <TextInput label="ra.field.currency" source="currency"/>

            <CompanyInput label="ra.field.company"/>

            <ReferenceInput label="ra.field.service_set" source={'serviceSet'}
                            reference={'service_sets'} {...props}>
                <SelectInput optionText="id"/>
            </ReferenceInput>

        </SimpleForm>
    </Edit>
)

export const TerminalCreate = (props) => (
    <Create actions={<CreateActions/>} {...props}>
        <SimpleForm toolbar={<CreateToolbar/>}>

            <TextInput label="ra.field.name" source="name" validate={required()}/>
            <TextInput label="ra.field.address" source="address" validate={required()}/>

            <TextInput label="ra.field.softwareVersion" source="softwareVersion" validate={required()}/>

            <NumberInput label="ra.field.pin" source={'pin'} validate={required()}/>

            <BooleanInput label="ra.field.active" source={'active'}/>

            <TextInput label="ra.field.currency" source="currency" validate={required()}/>

            <CompanyInput label="ra.field.company"/>

            <ReferenceInput label="ra.field.service_set" source={'serviceSet'}
                            reference={'service_sets'} {...props}>
                <SelectInput optionText="id"/>
            </ReferenceInput>

            <TextInput disabled defaultValue={`/api/users/${getUser()['id']}`} source={'user'}
                       reference={'users'}/>

        </SimpleForm>
    </Create>
);

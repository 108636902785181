import {ChipField} from 'react-admin';
import {DefaultLanguage, i18nProvider} from "../../Providers/i18nProvider";

const ChipFieldMultiLang = props => {
    let source = props.source;
    let record = props.record;
    if (typeof record[source] === "object") {
        if (record[source][i18nProvider.getLocale()]) {
            source = `${source}.${i18nProvider.getLocale()}`
        } else if (record[source][DefaultLanguage]) {
            source = `${source}.${DefaultLanguage}`
        } else {
            let firstKey = Object.keys(record[source])[0];
            if (firstKey) {
                source = `${source}.${firstKey}`
            }
        }
    }
    return (
        <ChipField source={source}/>
    )
}

export default ChipFieldMultiLang;
import {ChipField, ReferenceField, TextField} from "react-admin";
import CompanyProps from "../../Theme/CompanyProps";

const CompanyField = (props) => {

    const companyProps = CompanyProps()

    return (
        <div>
            <TextField label="ra.field.company"/>
            <ReferenceField {...companyProps} reference={props.reference ?? 'companies'}
                            source={props.source ?? 'company'} {...props}>
                <ChipField {...companyProps} source={'name'}/>
            </ReferenceField>
        </div>
    )
}

export default CompanyField
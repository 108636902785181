import jwtDecode from "jwt-decode";
import axios from "axios";
import {getUser, setUser} from './UserProvider';

export default {
    login: ({username, password}) => {
        return new Promise((resolve, reject) => {
            const request = new Request(
                `${process.env.REACT_APP_API_ENTRYPOINT}/auth/login`,
                {
                    method: "POST",
                    body: JSON.stringify({email: username, password}),
                    headers: new Headers({"Content-Type": "application/json"}),
                }
            )

            fetch(request)
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        reject()
                    }

                    return response.json();
                })
                .then(({token}) => {
                    localStorage.setItem("token", token);

                    axios.get(`${process.env.REACT_APP_API_ENTRYPOINT}/auth/me`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then((res) => {
                        setUser(res.data.user)
                        resolve()
                    }).catch((error) => {
                        reject()
                    })
                })
        })
    },
    logout: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        return Promise.resolve();
    },

    checkAuth: () => {
        try {
            if (
                !localStorage.getItem("token") ||
                new Date().getTime() / 1000 >
                jwtDecode(localStorage.getItem("token"))?.exp
            ) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },

    checkError: (err) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const role = {
            company: getUser().company,
            role: getUser().userGroup,
        }
        return role ? Promise.resolve(role) : Promise.reject();
    },
};

import * as React from 'react';
import {useSelector} from 'react-redux';
import {Collapse, Divider, List, useMediaQuery} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {DashboardMenuItem, getResources, MenuItemLink, useTranslate} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import {makeStyles} from '@material-ui/core/styles';
import ScoreIcon from '@material-ui/icons/Score';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import DvrIcon from '@material-ui/icons/Dvr';
import InsertChartIcon from "@material-ui/icons/InsertChart";

const useStyles = makeStyles({
    menuContainer: {
        ["@media (min-width: 960px)"]: {
            marginTop: "1.5em"
        }
    },
})

const Menu = ({onMenuClick, logout}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const [collapseOpen, setOpen] = React.useState(true);
    const resources = useSelector(getResources);

    const handleClick = () => {
        setOpen(!collapseOpen);
    };

    return (
        <div className={classes.menuContainer}>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open}/>
            <MenuItemLink
                to={`/terminal_states_latests`}
                primaryText={
                    translate('ra.reports.monitoring')
                }
                leftIcon={
                    <DvrIcon/>
                }
            />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
            >
                <Divider/>
                <MenuItemLink
                    onClick={handleClick}
                    to={`#`}
                    primaryText={
                        translate('ra.menu.reports')
                    }
                    leftIcon={
                        collapseOpen ? <ExpandLess/> : <ExpandMore/>
                    }
                />
                <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <MenuItemLink
                            to={`/salaries`}
                            primaryText={
                                translate('ra.reports.salary')
                            }
                            leftIcon={
                                <AttachMoneyIcon/>
                            }
                        />
                        <MenuItemLink
                            to={`/popular_services`}
                            primaryText={
                                translate('ra.reports.popularServices')
                            }
                            leftIcon={
                                <ScoreIcon/>
                            }
                        />
                        <MenuItemLink
                            to={`/encashments`}
                            primaryText={
                                translate('ra.reports.encashments')
                            }
                            leftIcon={
                                <LocalAtmIcon/>
                            }
                        />
                        <MenuItemLink
                            to={`/working_time_reports`}
                            primaryText={
                                translate('ra.reports.working_time_reports')
                            }
                            leftIcon={
                                <TimelapseIcon/>
                            }
                        />
                        <MenuItemLink
                            to={`/revenue_reports`}
                            primaryText={
                                translate('ra.reports.revenue_reports')
                            }
                            leftIcon={
                                <InsertChartIcon/>
                            }
                        />
                    </List>
                </Collapse>
                <Divider/>
            </List>
            {resources.map(resource => {
                let primaryText = (resource.options && resource.options.label) ? translate(resource.options.label) : resource.name
                if (resource.options.hideFromMenu) {
                    return null
                }

                return (
                    <div>
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={
                                primaryText
                            }
                            leftIcon={
                                resource.icon ? <resource.icon/> : <DefaultIcon/>
                            }
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <Divider/>
                    </div>
                )
            })}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;

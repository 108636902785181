import {useRecordContext} from "ra-core";


export const ArrayObjects = ({source}) => {
    const record = useRecordContext();
    let str = '';
    if (record[source] && Object.prototype.toString.call(record[source]) === '[object Array]') {
        record[source].map((item) => {
            let ar = Object.entries(item);
            str += `${ar[0][0]}: ${ar[0][1]}; `;
        })
    }

    return str;
}

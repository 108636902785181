import {DefaultLanguage, i18nProvider} from "../Providers/i18nProvider";

export const SelectArrayInputNameFormatter = item => {
    let value = '';
    if (typeof item.name === "object") {
        if (item.name[i18nProvider.getLocale()]) {
            value = item.name[i18nProvider.getLocale()]
        } else if (item.name[DefaultLanguage]) {
            value = item.name[DefaultLanguage]
        } else {
            value = Object.values(item.name)[0];
        }
    }
    return value
}
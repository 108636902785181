import {DefaultLanguage, i18nProvider, messages} from "../Providers/i18nProvider";

export const getTextFromTranslationsObject = (object) => {
    let locale = i18nProvider.getLocale();

    if (typeof object !== 'object') {
        return object
    }

    if (object[locale])
        return object[locale];

    if (object[DefaultLanguage])
        return object[DefaultLanguage];

    for (let i in Object.keys(messages)) {
        if (object[Object.keys(messages)[i]])
            return object[Object.keys(messages)[i]]
    }
}

import {defaultTheme} from 'react-admin';
import merge from 'lodash/merge';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';


const Theme = merge({}, defaultTheme, {
    palette: {
        primary: {
            main: "#ffffff"
        },
        secondary: {
            main: "#ffffff"
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            textPrimary: {
                color: "#e95727"
            },
            root: { // Name of the rule
                color: pink, // Some CSS
            },
        },
        MuiSwitch: {
            colorPrimary: {
                color: "#e95727",
                '&$checked': {
                    color: '#3D70B2',
                },
            },
            track: {
                "$checked$checked + &": {
                    opacity: 1.0,
                    backgroundColor: "#e95727"
                }
            }
        },
        MuiAppBar: {
            colorSecondary: {
                color: "#e95727"
            }
        }
    },
});

export default Theme

import {Layout} from 'react-admin';
import AppBar from "./HaircutAppBar"
import Menu from "./Menu"

const Haircut = props => <Layout
    {...props}
    appBar={AppBar}
    menu={Menu}
/>

export default Haircut;

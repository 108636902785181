import Aside from "../Components/Aside";
import ListActions from "../Components/Toolbars/TopToolbars/ListActions";
import {
    Create,
    Datagrid,
    Edit,
    List,
    NumberField,
    required,
    RichTextField,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from "react-admin";
import DateTimeField from "../Components/Fields/DateTimeField";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import RichTextInput from 'ra-input-rich-text';
import CompanyField from "../Components/Fields/CompanyField";
import * as React from "react";
import CompanyInput from "../Components/Inputs/CompanyInput";


export const NotificationsList = (props) => (
    <List title="ra.page.notifications"
          sort={{ field: 'updatedAt', order: 'DESC' }}
          aside={<Aside filters={{
        title: 'live',
        createdAt: 'dateRange'
    }}/>} {...props} actions={<ListActions/>} >
        <Datagrid rowClick="edit">
            <NumberField label="#" source="originId" sortBy={'id'}/>
            <TextField label="ra.field.title" source={'title'}/>
            <CompanyField label="ra.field.company"/>
            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>
        </Datagrid>
    </List>
)

export const NotificationsShow = (props) => (
    <Show actions={<ShowActions/>} {...props}>
        <SimpleShowLayout>

            <TextField label="ra.field.title" source={'title'}/>
            <RichTextField label="ra.field.content" source={'content'}/>
            <CompanyField/>
            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

        </SimpleShowLayout>
    </Show>
);

export const NotificationsEdit = (props) => (
    <Edit actions={<EditActions/>} {...props}>
        <SimpleForm>

            <TextInput label="ra.field.title" source="title" validate={required()}/>
            <RichTextInput label="ra.field.content" source="content" validate={required()}/>

            <CompanyInput label="ra.field.company"/>

        </SimpleForm>
    </Edit>
)

export const NotificationsCreate = (props) => (
    <Create actions={<CreateActions/>} {...props}>
        <SimpleForm toolbar={<CreateToolbar/>}>

            <TextInput label="ra.field.title" source="title" validate={required()}/>
            <RichTextInput label="ra.field.content" source="content" validate={required()}/>

            <CompanyInput label="ra.field.company"/>

        </SimpleForm>
    </Create>
);

const translation = {
    ra: {
        auth: {
            "username": "Correo electrónico",
            "password": "Clave",
            "sign_in": "Acceso",
            "user_menu": "Menú del Usuario",
            "logout": "Cerrar sesión"
        },
        validation: {
            required: "Campo requerido"
        },
        page: {
            loading: "Cargando",
            list: "Lista",
            dashboard: "Panel",
            users: "Administradores",
            userGroups: "Grupos de Usuarios",
            employees: "Empleados",
            customers: "Clientes(as)",
            taxGroups: "Grupos de impuestos",
            languages: "Idiomas",
            terminals: "Terminales",
            loyalty_programs: "Programas de lealtad",
            service_sets: "Servicios",
            companies: "Compañías",
            create: "Crear",
            loyaltyPrograms: "Programas de lealtad",
            serviceSets: "Servicios",
            notifications: "Notificaciones",
            encashments: "Cobros",
            orders: "Pedidos",
            count_orders: "Recuento de pedidos",
            revenue_orders: "Lista de pedidos en ingresos"

        },
        message: {
            loading: "espere por favor"
        },
        date: {
            today: "Hoy dia",
            this_week: "Esta semana",
            last_week: "La semana pasada",
            this_month: "Este mes",
            last_month: "El mes pasado"
        },
        action: {
            create: "Crear",
            export: "Exportar",
            show: "Espectáculo",
            delete: "Borrar",
            edit: "Editar",
            list: "Lista"
        },
        navigation: {
            page_range_info: "Información de rango",
            page_rows_per_page: "Filas por página",
            no_results: "No hay resultados ..."
        },
        buttons: {
            createServiceSet: "Crear conjunto de servicios",
            createServiceGroup: "Crear grupo de servicios",
            createService: "Crear servicio"
        },
        field: {
            name: "Nombre",
            createdAt: "Creado en",
            updatedAt: "Actualizado en",
            address: "Dirección",
            active: "Está activo",
            company: "Compañía",
            serviceSet: "Conjunto de servicios",
            email: "Correo electrónico",
            firstName: "Nombre de pila",
            lastName: "Apellido",
            percentage: "Porcentaje",
            code: "Código",
            id: "#",
            boolean: {
                "true": "sí",
                "false": "No"
            },
            taxGroup: "Grupo fiscal",
            loyaltyProgram: "Programa de fidelidad",
            service_group: "Grupo de servicio",
            cancel: "Cancelar",
            ordersCount: "Recuento de pedidos",
            ordersGrandTotals: "Suma de totales generales",
            salary: "Salario",
            from: "Desde",
            to: "Para",
            serviceId: "ID de servicio",
            serviceName: "Nombre del Servicio",
            count: "Contar",
            title: "Título",
            content: "Contenido",
            services: "Servicios",
            sum: "Suma",
            hours: "Horas",
            minutes: "Minutos",
            cash: "Dinero en efectivo",
            coins: "Monedas",
            telephone: "Teléfono",
            user_group: "Grupo de usuario",
            denomination: "Denominación",
            paid_by_card: "Pagado con tarjeta",
            without_employee: "No asignado al empleado",
            count_orders: "Recuento de pedidos",
        },
        button: {
            'save_and_show': "Guardar y mostrar",
            'save_and_add': "Guardar y agregar"
        },
        menu: {
            reports: "Informes"
        },
        reports: {
            salary: "Informe de salario",
            popularServices: "Popularidad de los servicios",
            encashments: "Informe de cobro",
            working_time_reports: "Informes de tiempo de trabajo",
            monitoring: "Vigilancia",
            revenue_reports: "Informes de ingresos"
        },
        text: {
            cash: "Dinero en efectivo",
            coins: "Monedas"
        }
    }
}

export default translation

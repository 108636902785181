import {Card, Col, Container, Row} from "react-bootstrap"
import * as React from "react";
import {List, useListContext, useTranslate} from "react-admin";

import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    terminal: {
        background: "red",
        padding: "1em",

    },
    terminalActive: {
        background: "green",
        padding: "1em",
    },
    textCenter: {
        textAlign: "center",
        fontSize: 16
    }
})

const JsonArrayParse = (data) => {
    // [{"50":9},{"20":16}]
    let result = "["
    for (let i = 0; i < data.length; i++) {
        if (!(data[i] === "{"
            || data[i] === '}'
            || data[i] === "["
            || data[i] === "]"
            || data[i] === '"'
            || data[i] === "'")) {
            if (!(data[i] === ":")) {
                result += data[i]
            } else {
                result += ","
            }
        }
    }
    result += "]"
    let arrayRes = JSON.parse(result)
    let denominations = []
    let counts = []
    for (let i = 0; i < arrayRes.length; i++) {
        if (i % 2 !== 0) {
            counts.push(arrayRes[i])
        } else {
            denominations.push(arrayRes[i])
        }
    }
    return [denominations, counts]
}
const tableRenderer = (data, classes) => {
    return (data.map((item) => {
        return <Row>
            <Col>
                <p className={classes.textCenter}>{item}</p>
            </Col>
        </Row>
    }))

}

const Grid = () => {
    const {data} = useListContext();
    const classes = useStyles();
    const translate = useTranslate();
    const counttransKey = translate("ra.field.count");
    const denomtransKey = translate("ra.field.denomination");
    return (
        <Container fluid style={{padding: "1em"}}>
            <Card><span className={classes.textCenter}>{`D - ${denomtransKey}, C - ${counttransKey}`}</span></Card>

            <Row>
                {Object.values(data).map((state) => {
                    let parsedCoins = JsonArrayParse(state.coins)
                    let parsedCash = JsonArrayParse(state.cash)
                    return (
                        <Col md={4} key={state.originId}>
                            <Card>
                                <Card className={(state.active) ? classes.terminalActive : classes.terminal}></Card>

                                <Card>
                                    <Row>
                                        <Col>
                                            <p className={classes.textCenter}>{translate('ra.field.terminal')}</p>
                                        </Col>
                                        <Col>
                                            <p className={classes.textCenter}>{translate('ra.text.cash')}</p>
                                        </Col>
                                        <Col>
                                            <p className={classes.textCenter}>{translate('ra.text.coins')}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p className={classes.textCenter}> {state.terminalName} </p>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col><p className={classes.textCenter}>{"D"}</p></Col>
                                                <Col><p className={classes.textCenter}>{"C"}</p></Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {tableRenderer(parsedCash[0], classes)}
                                                </Col>
                                                <Col>
                                                    {tableRenderer(parsedCash[1], classes)}
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col><p className={classes.textCenter}>{"D"}</p></Col>
                                                <Col><p className={classes.textCenter}>{"C"}</p></Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {tableRenderer(parsedCoins[0], classes)}
                                                </Col>
                                                <Col>
                                                    {tableRenderer(parsedCoins[1], classes)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>


                                </Card>
                            </Card>
                        </Col>
                    )
                })}
            </Row>

        </Container>

    )
}

export const Monitoring = (props) => {
    return (
        <List title="ra.reports.monitoring" {...props}>
            <Grid/>
        </List>
    )
}

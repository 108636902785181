import {TreeItem, TreeView} from '@material-ui/lab';
import {
    Create,
    DeleteButton,
    Edit,
    EditButton,
    List,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TranslatableFields,
    useListContext,
    useTranslate
} from 'react-admin';
import {Col, Container, Row} from "react-bootstrap"
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";
import CompanyInput from "../Components/Inputs/CompanyInput";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import CompanyField from "../Components/Fields/CompanyField";
import DateTimeField from "../Components/Fields/DateTimeField";
import {GetAllowedLanguages} from "../Providers/i18nProvider";
import {getTextFromTranslationsObject} from "../Helpers/i18nHelper"
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as React from 'react'

import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

const useStyles = makeStyles({
    serviceSet: {
        border: '1px solid gray'
    },
    serviceGroup: {
        borderTop: '1px solid gray',
        borderBottom: '1px solid gray'
    },
    withPadding: {
        padding: '20px 0'
    },
    noPadding: {
        padding: "2em",
        margin: "0",

        width: "45em"
    },
    maxWidth: {
        width: "38.5em"
    },
    maxWidth2: {

        width: "41em"
    },
})

const Tree = () => {
    const {data} = useListContext();
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Container fluid>
            <Row className={classes.serviceSet}>
                <Col>
                    {Object.values(data).map((serviceSet) => {
                        return (
                            <TreeItem
                                nodeId={"s" + serviceSet['@id']} label={
                                <Container fluid>
                                    <Row className={classes.withPadding}>
                                        <Col md={8}>{getTextFromTranslationsObject(serviceSet.name)}</Col>
                                        <Col md={2}><EditButton basePath="/service_sets" label="ra.action.edit"
                                                                record={serviceSet}/></Col>
                                        <Col md={2}><ShowButton basePath="/service_sets" label="ra.action.show"
                                                                record={serviceSet}/></Col>
                                    </Row>
                                </Container>
                            }>
                                <Container fluid>
                                    <Row className={classes.serviceGroup}>
                                        <Col>
                                            {
                                                serviceSet.serviceGroups.map((serviceGroup) => {
                                                    /** Edit / Show button not working without @id **/
                                                    serviceGroup.id = serviceGroup['@id']
                                                    return (
                                                        <TreeItem
                                                            nodeId={'g' + serviceSet['@id'] + serviceGroup['@id']}
                                                            label={
                                                                <Container fluid>
                                                                    <Row className={classes.withPadding}>
                                                                        <Col md={8}>{getTextFromTranslationsObject(serviceGroup.name)}</Col>
                                                                        <Col md={2}><EditButton
                                                                            basePath="/service_groups"
                                                                            label="ra.action.edit"
                                                                            record={serviceGroup}/></Col>
                                                                        <Col md={2} ><ShowButton
                                                                            basePath="/service_groups"
                                                                            label="ra.action.show"
                                                                            record={serviceGroup}/></Col>
                                                                    </Row>
                                                                </Container>
                                                            }>
                                                            <Container fluid>
                                                                <Row className={classes.serviceGroup}>
                                                                    <Col>
                                                                        {
                                                                            serviceGroup.services.map((service) => {
                                                                                /** Edit / Show button not working without @id **/
                                                                                service.id = service['@id']
                                                                                return (
                                                                                    <TreeItem
                                                                                        nodeId={'s' + serviceSet['@id'] + serviceGroup['@id'] + service['@id']}
                                                                                        label={
                                                                                            <Container fluid>
                                                                                                <Row
                                                                                                    className={classes.withPadding}>
                                                                                                    <Col
                                                                                                        md={6}>{getTextFromTranslationsObject(service.name)}</Col>
                                                                                                     <Col
                                                                                                    md={2}><EditButton
                                                                                                    basePath="/services"
                                                                                                    label="ra.action.edit"
                                                                                                    record={service}/></Col>
                                                                                                     <Col
                                                                                                    md={2} ><ShowButton
                                                                                                    basePath="/services"
                                                                                                    label="ra.action.show"
                                                                                                    record={service}/></Col>
                                                                                                    <Col
                                                                                                        md={2}><DeleteButton
                                                                                                        basePath="/services"
                                                                                                        label="ra.action.delete"
                                                                                                        record={service}/></Col>
                                                                                                </Row>
                                                                                            </Container>
                                                                                        }/>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Col className={classes.withPadding}
                                                                             md={{span: 2, offset: 5}}>
                                                                            <Button
                                                                                variant="outlined"
                                                                                component={Link}
                                                                                to={{
                                                                                    pathname: '/services/create',
                                                                                    state: {record: {serviceGroup: serviceGroup['@id']}},
                                                                                }}
                                                                            >
                                                                                {translate('ra.buttons.createService')}
                                                                            </Button>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </TreeItem>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className={classes.withPadding} md={{span: 2, offset: 5}}>
                                            <Button
                                                variant="outlined"
                                                component={Link}
                                                to={{
                                                    pathname: '/service_groups/create',
                                                    state: {record: {serviceSet: serviceSet['@id']}},
                                                }}
                                            >
                                                {translate('ra.buttons.createServiceGroup')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </TreeItem>
                        )
                    })}
                </Col>
            </Row>
            <Row>
                <Col className={classes.withPadding} md={{span: 2, offset: 5}}>
                    <Button
                        variant="outlined"
                        component={Link}
                        to={{
                            pathname: '/service_sets/create',
                            state: {},
                        }}
                    >
                        {translate('ra.buttons.createServiceSet')}
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

export const ServiceSetList = (props) => {
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };
    return (
        <List title="ra.page.services" {...props}>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
            >
                <Tree/>
            </TreeView>
        </List>
    )
}

export const ServiceSetShow = (props) => (
    <Show actions={<ShowActions/>} {...props}>
        <SimpleShowLayout>

            <TranslatableFields locales={Object.keys(GetAllowedLanguages())}
                                defaultLocale={Object.keys(GetAllowedLanguages())[0]}
                                label="ra.field.name">
                <TextField source="name"/>
            </TranslatableFields>

            <TextField label="ra.field.company"/>
            <CompanyField/>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

        </SimpleShowLayout>
    </Show>
)

export const ServiceSetEdit = (props) => {
    const classes = useStyles();
    return (
        <Edit actions={<EditActions/>} {...props}>
            <SimpleForm>

                <Container fluid className={classes.noPadding}>

                    <TranslatableFields locales={Object.keys(GetAllowedLanguages())}
                                        defaultLocale={Object.keys(GetAllowedLanguages())[0]}
                                        label="ra.field.name">
                        <TextInput className={classes.maxWidth} source="name"/>
                    </TranslatableFields>

                    <CompanyInput className={classes.maxWidth2} label="ra.field.company"/>
                </Container>

            </SimpleForm>
        </Edit>
    )
}

export const ServiceSetCreate = (props) => {
    const classes = useStyles();
    return (
        <Create actions={<CreateActions/>} {...props}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                <Container fluid className={classes.noPadding}>

                    <TranslatableFields locales={Object.keys(GetAllowedLanguages())}
                                        defaultLocale={Object.keys(GetAllowedLanguages())[0]}
                                        label="ra.field.name">
                        <TextInput className={classes.maxWidth} source="name"/>
                    </TranslatableFields>

                    <CompanyInput className={classes.maxWidth2} label="ra.field.company"/>
                </Container>


            </SimpleForm>
        </Create>
    )
}

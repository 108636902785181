import {useRecordContext} from "ra-core";
import {i18nProvider, messages} from "../../Providers/i18nProvider";

const chip = (name) => {
    return (
        <div className="MuiButtonBase-root MuiChip-root MuiChip-clickable m-1" tabIndex="0"
             role="button">
            <span className="MuiChip-label">{name}</span>
            <span className="MuiTouchRipple-root"/>
        </div>
    )
}

const text = (name) => {
    return (
        <span className="MuiTypography-root MuiTypography-body2">{name}</span>
    );
}


export const NameField = ({source}) => {
    let name = '';
    const record = useRecordContext();
    let locale = i18nProvider.getLocale();
    // if (typeof record[source] === "string"){
    //     let property =JSON.parse(record[source])
    //     console.log(property[source])
    //     return text(property[source])
    // }
    if ((name = record[source][locale]))
        return text(name);


    if ((name = record[source]['en']))
        return text(name);


    for (let i in Object.keys(messages)) {
        if ((name = record[source][Object.keys(messages)[i]]))
            return text(name);
    }
};

export const NameChipField = ({source}) => {
    let name = '';
    const record = useRecordContext();
    let locale = i18nProvider.getLocale();

    if ((name = record[source][locale]))
        return chip(name);

    if ((name = record[source]['en']))
        return chip(name);

    for (let i in Object.keys(messages)) {
        if ((name = record[source][Object.keys(messages)[i]]))
            return chip(name);
    }
};

import React from 'react';
import {DeleteButton, ListButton, ShowButton, TopToolbar} from "react-admin";


const EditActions = ({basePath, data, resource}) => {
    return (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
            <ListButton/>
            <DeleteButton undoable={false}/>
        </TopToolbar>
    )
};

export default EditActions;

import {
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    List,
    NumberField,
    PasswordInput,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import DateTimeField from "../Components/Fields/DateTimeField";
import ListActions from "../Components/Toolbars/TopToolbars/ListActions";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import Aside from "../Components/Aside";
import CompanyField from "../Components/Fields/CompanyField";
import CompanyInput from "../Components/Inputs/CompanyInput";

const Title = ({record}) => {
    return <span>{record ? `#${record.uid}: ${record.firstName} ${record.lastName}` : ''}</span>;
};

export const EmployeeList = (props) => (
    <List
        title="ra.page.employees"
        sort={{ field: 'updatedAt', order: 'DESC' }}
        aside={<Aside filters={{
            id: 'live',
            email: 'live',
            firstName: 'live',
            lastName: 'live',
            telephone: 'live',
            createdAt: 'dateRange'
        }}/>} {...props} actions={<ListActions/>} >
        <Datagrid rowClick="edit">

            <NumberField label="#" source="originId" sortBy={'id'}/>

            <TextField label="ra.field.email" source={'email'}/>
            <TextField label="ra.field.fullname" source={'name'}/>
            <TextField label="ra.field.firstName" source={'firstName'}/>
            <TextField label="ra.field.lastName" source={'lastName'}/>
            <TextField label="ra.field.telephone" source={'telephone'}/>

            <CompanyField label="ra.field.company"/>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>

            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

            <ShowButton/>
            <DeleteButton/>

        </Datagrid>
    </List>
)

export const EmployeeShow = (props) => (
    <Show actions={<ShowActions/>} title={<Title/>} {...props}>
        <SimpleShowLayout>

            <TextField label="ra.field.email" source={'email'}/>
            <TextField label="ra.field.fullname" source={'name'}/>
            <TextField label="ra.field.firstName" source={'firstName'}/>
            <TextField label="ra.field.lastName" source={'lastName'}/>

            <TextField label="ra.field.telephone" source={'telephone'}/>

            <TextField label="ra.field.barcode" source={'barcode'}/>

            <TextField label="ra.field.company"/>
            <CompanyField/>

            <TextField label="ra.field.createdAt"/>
            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>

            <TextField label="ra.field.updatedAt"/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

        </SimpleShowLayout>
    </Show>
)

export const EmployeeEdit = (props) => (
    <Edit actions={<EditActions/>} title={<Title/>} {...props}>
        <SimpleForm>

            <TextInput label="ra.field.email" source="email"/>
            <TextInput label="ra.field.fullname" source="name"/>
            <TextInput label="ra.field.firstName" source="firstName"/>
            <TextInput label="ra.field.lastName" source="lastName"/>
            <TextInput label="ra.field.telephone" source="telephone"/>
            <CompanyInput label="ra.field.company"/>

        </SimpleForm>
    </Edit>
)

export const EmployeeCreate = (props) => (
    <Create actions={<CreateActions/>} {...props}>
        <SimpleForm toolbar={<CreateToolbar/>}>

            <TextInput label="ra.field.email" source="email" validate={required()}/>
            <TextInput label="ra.field.fullname" source="name" validate={required()}/>
            <TextInput label="ra.field.firstName" source="firstName" validate={required()}/>
            <TextInput label="ra.field.lastName" source="lastName" validate={required()}/>

            <TextInput label="ra.field.telephone" source="telephone"/>

            <PasswordInput label="ra.field.password" source="plainPassword" validate={required()}/>

            <CompanyInput label="ra.field.company"/>

        </SimpleForm>
    </Create>
);

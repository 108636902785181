const translation = {
    ra: {
        page: {
            orders: "Zamówienia",
            employees: "Pracownicy",
            serviceSets: "Zestawy usług",
            services: "Usługi",
            serviceGroups: "Grupy usług",
            taxGroups: "Grupy podatkowe",
            terminals: "Terminali",
            loyaltyPrograms: "Programy lojalnościowe",
            customers: "Klienci",
            companies: "Firmy",
            users: "Administratorzy",
            locations: "Lokalizacje",
            list: "Lista",
            telephone: "Telefon",
            user_group: "Grupa"
        },
        action: {
            show: "Pokazać",
            export: "Eksport",
            delete: "Usunąć",
            edit: "Edytować",
            create: "Stwórzyć"
        }
    }
}

export default translation

import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    List,
    NumberField,
    PasswordInput,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import DateTimeField from "../Components/Fields/DateTimeField";
import ListActions from "../Components/Toolbars/TopToolbars/ListActions";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import Aside from "../Components/Aside";
import CompanyField from "../Components/Fields/CompanyField";
import CompanyInput from "../Components/Inputs/CompanyInput";

const Title = ({record}) => {
    return <span>{record ? `#${record.uid}: ${record.firstName} ${record.lastName}` : ''}</span>;
};

export const UsersList = (props) => (
    <List
        title="ra.page.users"
        sort={{ field: 'updatedAt', order: 'DESC' }}
        aside={<Aside filters={{
            id: 'live',
            email: 'live',
            firstName: 'live',
            lastName: 'live',
            active: 'boolean',
            createdAt: 'dateRange'
        }}/>} {...props} actions={<ListActions/>}>
        <Datagrid rowClick="edit">

            <NumberField label="#" source="originId" sortBy={'id'}/>

            <TextField label="ra.field.email" source={'email'}/>
            <TextField label="ra.field.firstName" source={'firstName'}/>
            <TextField label="ra.field.lastName" source={'lastName'}/>

            <BooleanField label="ra.field.active" source={'active'}/>

            <CompanyField label="ra.field.company"/>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

            <ShowButton/>
            <DeleteButton/>

        </Datagrid>
    </List>
)

export const UsersShow = (props) => (
    <Show actions={<ShowActions/>} title={<Title/>} {...props}>
        <SimpleShowLayout>

            <TextField label="ra.field.email" source={'email'}/>
            <TextField label="ra.field.firstName" source={'firstName'}/>
            <TextField label="ra.field.lastName" source={'lastName'}/>

            <BooleanField label="ra.field.active" source={'active'}/>

            <TextField label="ra.field.company"/>
            <CompanyField/>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

        </SimpleShowLayout>
    </Show>
)

export const UsersEdit = (props) => (
    <Edit actions={<EditActions/>} title={<Title/>} {...props}>
        <SimpleForm>

            <TextInput label="ra.field.email" source="email"/>
            <PasswordInput label="ra.field.password" source="plainPassword" validate={required()}/>
            <TextInput label="ra.field.firstName" source="firstName"/>
            <TextInput label="ra.field.lastName" source="lastName"/>

            <BooleanInput label="ra.field.active" source='active'/>

            <CompanyInput label="ra.field.company"/>

            <ReferenceInput label="ra.field.user_group" source='userGroup' reference='user_groups' {...props}>
                <SelectInput optionText="name"/>
            </ReferenceInput>

        </SimpleForm>
    </Edit>
)

export const UsersCreate = (props) => (
    <Create actions={<CreateActions/>} {...props}>
        <SimpleForm toolbar={<CreateToolbar/>}>

            <TextInput label="ra.field.email" source="email" validate={required()}/>
            <TextInput label="ra.field.firstName" source="firstName" validate={required()}/>
            <TextInput label="ra.field.lastName" source="lastName" validate={required()}/>

            <PasswordInput label="ra.field.password" source="plainPassword" validate={required()}/>

            <BooleanInput label="ra.field.active" source='active' defaultValue={true}/>

            <CompanyInput label="ra.field.company" validate={required()}/>

            <ReferenceInput label="ra.field.user_group" source='userGroup' reference='user_groups'
                            validate={required()}>
                <SelectInput optionText="name"/>
            </ReferenceInput>

        </SimpleForm>
    </Create>
);

import React from 'react';
import {ListButton, TopToolbar} from "react-admin";


const CreateAction = () => (
    <TopToolbar>
        <ListButton/>
    </TopToolbar>
);

export default CreateAction

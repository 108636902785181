import {AppBar, UserMenu} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
    avatar: {
        height: 30,
        width: 30,
    },
});

const MyCustomIcon = () => {
    const classes = useStyles();
    return (
        <Avatar
            className={classes.avatar}
        />
    )
};

const MyUserMenu = props => (<UserMenu {...props} icon={<MyCustomIcon/>}/>);

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu/>}/>;

export default MyUserMenu

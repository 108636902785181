import {
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TranslatableFields,
} from 'react-admin'
import DateTimeField from "../Components/Fields/DateTimeField";
import ListActions from "../Components/Toolbars/TopToolbars/ListActions";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import Aside from "../Components/Aside";
import CompanyInput from "../Components/Inputs/CompanyInput";
import CompanyField from "../Components/Fields/CompanyField";
import {NameChipField, NameField} from "../Components/Fields/NameField";
import {GetAllowedLanguages} from "../Providers/i18nProvider"
import {SelectArrayInputNameFormatter} from "../Decorators/SelectArrayInputNameFormatter"
import {Container} from "react-bootstrap"
import {makeStyles} from '@material-ui/core/styles';
import * as React from "react";

const useStyles = makeStyles({

    noPadding: {
        padding: "2em",
        margin: "0",

        width: "45em"
    },
    maxWidth: {
        width: "38.5em"
    },
    maxWidth2: {

        width: "41em"
    },

})

export const ServiceGroupList = (props) => (
    <List
        sort={{ field: 'updatedAt', order: 'DESC' }}
        aside={<Aside filters={{
        id: 'live',
        createdAt: 'dateRange'
    }}/>} {...props} actions={<ListActions/>} >
        <Datagrid rowClick="edit">

            <NumberField label="#" source="originId" sortBy={'id'}/>

            <NameField source="name" label="ra.field.name"/>

            <CompanyField label="ra.field.company"/>

            <ReferenceField reference={'service_sets'} source={'serviceSet'}>
                <NameChipField source={'name'}/>
            </ReferenceField>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

            <ShowButton/>
            <DeleteButton/>

        </Datagrid>
    </List>
)

export const ServiceGroupShow = (props) => (
    <Show actions={<ShowActions/>} {...props}>
        <SimpleShowLayout>

            <TranslatableFields locales={Object.keys(GetAllowedLanguages())}
                                defaultLocale={Object.keys(GetAllowedLanguages())[0]}
                                label="ra.field.name">
                <TextField source="name"/>
            </TranslatableFields>

            <TextField label="ra.field.company"/>
            <CompanyField/>

            <ReferenceField reference={'service_sets'} source={'serviceSet'}>
                <NameChipField source={'name'}/>
            </ReferenceField>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

        </SimpleShowLayout>
    </Show>
)

export const ServiceGroupEdit = (props) => {
    const classes = useStyles();
    return (
        <Edit actions={<EditActions/>} {...props}>
            <SimpleForm>
                <Container fluid className={classes.noPadding}>
                    <TranslatableFields locales={Object.keys(GetAllowedLanguages())}
                                        defaultLocale={Object.keys(GetAllowedLanguages())[0]}
                                        label="ra.field.name">
                        <TextInput className={classes.maxWidth} source="name"/>
                    </TranslatableFields>

                    <CompanyInput className={classes.maxWidth2} label="ra.field.company"/>

                    <ReferenceInput className={classes.maxWidth2} label="ra.field.serviceSet" source={'serviceSet'}
                                    reference={'service_sets'} {...props}>
                        <SelectInput optionText={SelectArrayInputNameFormatter}/>
                    </ReferenceInput>
                </Container>

            </SimpleForm>
        </Edit>
    )
}

export const ServiceGroupCreate = (props) => {
    const classes = useStyles();

    return (
        <Create actions={<CreateActions/>} {...props}>
            <SimpleForm toolbar={<CreateToolbar/>}>

                <Container fluid className={classes.noPadding}>
                    <TranslatableFields locales={Object.keys(GetAllowedLanguages())}
                                        defaultLocale={Object.keys(GetAllowedLanguages())[0]}
                                        label="ra.field.name">
                        <TextInput className={classes.maxWidth} source="name"/>
                    </TranslatableFields>

                    <CompanyInput className={classes.maxWidth2} label="ra.field.company"/>

                    <ReferenceInput className={classes.maxWidth2} label="ra.field.service_set" source={'serviceSet'}
                                    reference={'service_sets'} {...props}>
                        <SelectInput optionText={SelectArrayInputNameFormatter}/>
                    </ReferenceInput>
                </Container>

            </SimpleForm>
        </Create>
    )
}

import {Datagrid, List, TextField} from "react-admin";
import Aside from "../../Components/Aside";

export const SalaryList = (props) => (
    <List title="ra.reports.salary"
          {...props} sort={{ field: 'updatedAt', order: 'DESC' }}
          aside={<Aside filters={{
              date: 'dateRange',
              employee: {
                  'filter': 'resource',
                  'resource': "employees",
                  'optionText': 'email',
                  'multiple': true
              }
          }}/>}>
        <Datagrid>
            <TextField label="ra.field.firstName" source={'firstName'}/>
            <TextField label="ra.field.lastName" source={'lastName'}/>
            <TextField label="ra.field.email" source={'email'}/>
            <TextField label="ra.field.ordersCount" source={'orders_count'}/>
            <TextField label="ra.field.ordersGrandTotals" source={'orders_grand_totals'}/>
            <TextField label="ra.field.salary" source={'salary'}/>
        </Datagrid>
    </List>
)

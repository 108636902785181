import * as React from 'react';
import {Form} from 'react-final-form';
import {Box} from '@material-ui/core';
import {SelectInput, useGetList, useListContext} from 'react-admin';
import Loading from "../../Loading"

const CompanyFilter = () => {
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter,
    } = useListContext();

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        } else {
            hideFilter("main");
        }
    };

    let companies = [
        {name: "ra.field.values.all", id: null}
    ];

    const {data, ids, total, loading, loaded, error} = useGetList("companies");
    if (loading) {
        return <Loading/>;
    } else {
        Object.values(data).map((item) => {
            companies.push(item)
        })
    }
    if (error) {
        return <p>ERROR</p>;
    }


    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <Box component="span" mr={12}>
                                <SelectInput source="company" choices={companies} optionText="name" optionValue="id"
                                             onChange={handleSubmit}/>
                            </Box>
                        </Box>
                    </form>
                )}
            </Form>
        </div>
    );
}

export default CompanyFilter;

import {resolveBrowserLocale, useGetList} from 'react-admin';
import polyglotI18nProvider from "ra-i18n-polyglot";
import enMessages from "../i18n/en";
import plMessages from "../i18n/pl";
import ruMessages from "../i18n/ru";
import esMessages from "../i18n/es";
import csMessages from "../i18n/cs";
import {getUser} from "./UserProvider"
import * as React from "react";

export const messages = {
    "en": enMessages,
    "pl": plMessages,
    "ru": ruMessages,
    "es": esMessages,
    "cs": csMessages
};

export const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale()
);

export const DefaultLanguage = 'en';


export const GetAllowedLanguages = () => {
    const user = getUser();
    const {data, ids, total, loading, loaded, error} = useGetList("languages");
    let result = [];
    if (loading) {
        return [];
    }
    if (error) {
        return [];
    }
    if (user && user.company) {
        Object.values(data).map((item) => {
            if (messages[item.code] !== undefined) {
                result[item.code] = item.code;
            }
        })
    } else {
        return messages;
    }


    return result;
}

import React, {cloneElement} from 'react';
import {CreateButton, ExportButton, sanitizeListRestProps, TopToolbar, useListContext} from "react-admin";


const ListActions = (props) => {
    const {
        filters,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={`/${resource}`} label='ra.action.create'/>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
            />
        </TopToolbar>
    );
}

export default ListActions;

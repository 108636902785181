import {
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    List,
    NumberField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin'
import DateTimeField from "../Components/Fields/DateTimeField";
import ListActions from "../Components/Toolbars/TopToolbars/ListActions";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import Aside from "../Components/Aside";

const Title = ({record}) => {
    return <span>{record ? `#${record.uid}: ${record.name}` : ''}</span>;
};

export const CompanyList = (props) => (
    <List
        title="ra.page.companies"
        sort={{ field: 'updatedAt', order: 'DESC' }}
        aside={<Aside filters={{
            id: 'live',
            name: 'live',
            createdAt: 'dateRange'
        }}/>} {...props} actions={<ListActions/>}>
        <Datagrid rowClick="edit">

            <NumberField label="#" source="originId" sortBy={'id'}/>

            <TextField label="ra.field.name" source={'name'}/>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

            <ShowButton/>
            <DeleteButton/>

        </Datagrid>
    </List>
);

export const CompanyShow = (props) => (
    <Show actions={<ShowActions/>} title={<Title/>} {...props}>
        <SimpleShowLayout>

            <TextField label="ra.field.name" source={'name'}/>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

        </SimpleShowLayout>
    </Show>
);

export const CompanyEdit = (props) => (
    <Edit actions={<EditActions/>} title={<Title/>} {...props}>
        <SimpleForm>

            <TextInput label="ra.field.name" source="name"/>

        </SimpleForm>
    </Edit>
)

export const CompanyCreate = (props) => (
    <Create actions={<CreateActions/>} {...props}>
        <SimpleForm toolbar={<CreateToolbar/>}>

            <TextInput label="ra.field.name" source="name" validate={required()}/>

        </SimpleForm>
    </Create>
);

import {useRecordContext} from "ra-core";
import Typography from "@material-ui/core/Typography";

const DateTimeField = (props) => {
    const {className, source} = props;
    const record = useRecordContext();
    const DateTime = new Date(record[source])

    let DateTimeText = `${DateTime.getFullYear()}-${DateTime.getMonth() + 1}-${DateTime.getDate()} 
    ${DateTime.getHours()}:${DateTime.getMinutes()}:${DateTime.getSeconds()}`;

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
        >

            {DateTimeText}
        </Typography>
    );
}

export default DateTimeField

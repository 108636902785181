import {List, ReferenceField, TextField} from "react-admin";
import Aside from "../Components/Aside";
import ChipFieldMultiLang from "../Components/Fields/ChipFieldMultiLang";
import DateTimeField from "../Components/Fields/DateTimeField";
import DataGridWithTotals from "../Components/DataGridWithTotals"
import {ArrayObjects} from "../Components/Fields/ArrayObjects"

export const EncashmentsList = (props) => (
    <List
        title="ra.page.encashments"
        {...props}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        aside={<Aside filters={{
            createdAt: 'dateRange',
            terminal: {
                'filter': 'resource',
                'resource': "terminals",
                'optionText': 'name',
                'multiple': true
            }
        }}/>}>
        <DataGridWithTotals fieldsForSum={['sum']}>
            <ReferenceField label={"ra.field.terminal"} reference={"terminals"} source={"terminal"}>
                <ChipFieldMultiLang source={"name"}/>
            </ReferenceField>
            <ArrayObjects label={"ra.field.cash"} source={"cash"}/>
            <ArrayObjects label={"ra.field.coins"} source={"coins"}/>
            <TextField label={"ra.field.sum"} source={"sum"}/>
            <DateTimeField label={"ra.field.createdAt"} source={"createdAt"}/>
            <DateTimeField label={"ra.field.updatedAt"} source={"updatedAt"}/>
        </DataGridWithTotals>
    </List>
)

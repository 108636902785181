import {makeStyles} from "@material-ui/core/styles";
import {getUser} from "../Providers/UserProvider";

const CompanyProps = () => {
    const useStyles = makeStyles({
        companyCell: {
            display: 'none'
        },
    })

    const classes = useStyles();

    // Hide company cell if user not belongs to any company
    if (getUser().company !== null) {
        return {
            headerClassName: classes.companyCell,
            cellClassName: classes.companyCell,
            formClassName: classes.companyCell,
            className: classes.companyCell,
            label: false,
        }
    }

    return {}
}

export default CompanyProps

import {
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DateTimeInput,
    DeleteButton,
    Edit,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TranslatableFields
} from 'react-admin'
import DateTimeField from "../Components/Fields/DateTimeField";
import ListActions from "../Components/Toolbars/TopToolbars/ListActions";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import Aside from "../Components/Aside";
import CompanyInput from "../Components/Inputs/CompanyInput";
import CompanyField from "../Components/Fields/CompanyField";
import {NameField} from "../Components/Fields/NameField";
import {GetAllowedLanguages, messages} from "../Providers/i18nProvider"
import {SelectArrayInputNameFormatter} from "../Decorators/SelectArrayInputNameFormatter"
import {makeStyles} from '@material-ui/core/styles';
import {Card, Col, Container, Row} from "react-bootstrap";


const useStyles = makeStyles({
    noPadding: {
        padding: "2em",
        margin: "0",

        width: "45em"
    },
    dateWidth: {
        width: "10em"
    },
    maxWidth: {
        width: "38.5em"
    },

    allowCC: {
        "padding-top": "0.8em",

    },
    customPadding: {
        "padding-top": "1em",
        "padding-left": "1em"
    },
    maxWidth2: {

        width: "41em"
    },

});
export const ServiceList = (props) => (
    <List title="ra.field.services"
          sort={{ field: 'updatedAt', order: 'DESC' }}
          aside={<Aside filters={{
        id: 'live',
        createdAt: 'dateRange'
    }}/>} {...props} actions={<ListActions/>} >
        <Datagrid rowClick="edit">

            <NumberField label="#" source="originId" sortBy={'id'}/>

            <NameField source="name" label="ra.field.name"/>

            <CompanyField label="ra.field.company"/>

            <ReferenceField reference={'service_groups'} source={'serviceGroup'}>
                <ChipField source={'id'}/>
            </ReferenceField>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

            <ShowButton/>
            <DeleteButton/>

        </Datagrid>
    </List>
)

export const ServiceShow = (props) => (
    <Show actions={<ShowActions/>} {...props}>
        <SimpleShowLayout>

            <TranslatableFields locales={Object.keys(messages)} defaultLocale={Object.keys(messages)[0]}
                                label="ra.field.name">
                <TextField source="name"/>
            </TranslatableFields>

            <ReferenceField reference={'service_groups'} source={'serviceGroup'}>
                <ChipField source={'id'}/>
            </ReferenceField>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

            <CompanyField/>

        </SimpleShowLayout>
    </Show>
)

export const ServiceEdit = (props) => {
    const classes = useStyles();
    return (
        <Edit actions={<EditActions/>} {...props}>
            <SimpleForm>
                <Container className={classes.noPadding} fluid>
                    <Row>

                        <Col><TranslatableFields locales={Object.keys(messages)}
                                                 defaultLocale={Object.keys(messages)[0]}
                                                 label="ra.field.name" validate={required()}>
                            <TextInput className={classes.maxWidth} source="name"/>
                        </TranslatableFields>
                        </Col>
                    </Row>
                    <Card>
                        <Row className={classes.customPadding}>
                            <Col md={3}><NumberInput source="price"/></Col>
                            <Col md={3}><NumberInput source="rewardFixed"/></Col>
                            <Col md={3}><NumberInput source="rewardPercentage"/></Col>
                            <Col md={3}><BooleanInput className={classes.allowCC} source="allowCC"/></Col>

                        </Row>

                        <Row className={classes.customPadding}><Col><ReferenceInput className={classes.maxWidth}
                                                                                    label="ra.field.service_group"
                                                                                    source={'serviceGroup'}
                                                                                    reference={'service_groups'} {...props}>
                            <SelectInput optionText={SelectArrayInputNameFormatter}/>
                        </ReferenceInput></Col>
                        </Row>
                        <Row className={classes.customPadding}><Col><ReferenceInput className={classes.maxWidth}
                                                                                    label="ra.field.loyaltyProgram"
                                                                                    source={'loyaltyProgram'}
                                                                                    allowEmpty={true}
                                                                                    reference={'loyalty_programs'} {...props}>
                            <SelectInput optionText={'name'}/>
                        </ReferenceInput></Col></Row>
                        <Row className={classes.customPadding}> <Col><CompanyInput className={classes.maxWidth}
                                                                                   label="ra.field.company"/></Col></Row>
                        <Row className={classes.customPadding}>


                            <Col>

                                <Row>
                                    <Col>
                                        <SelectArrayInput choices={AllCoins} className={classes.maxWidth}
                                                          source="allowedCoins"/>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col><SelectArrayInput choices={AllBills} className={classes.maxWidth}
                                                           source="allowedBills"/></Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <ReferenceInput label="ra.field.taxGroup" source={'taxGroup'}
                                                        reference={'tax_groups'} {...props}>
                                            <SelectInput optionText={'name'}/>
                                        </ReferenceInput>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><NumberInput className={classes.dateWidth} source="sortOrder"/></Col>

                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col><DateTimeInput className={classes.dateWidth} source="startDate"/></Col>

                                </Row>
                                <Row>
                                    <Col><DateTimeInput className={classes.dateWidth} source="endDate"/></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                </Container>
            </SimpleForm>
        </Edit>
    )
}

const AllCoins = [
    {id: "0.1", name: "0.10"},
    {id: "0.2", name: "0.20"},
    {id: "0.5", name: "0,=.50"},
    {id: "1", name: "1"},
    {id: "2", name: "2"},
    {id: "5", name: "5"}
]

const AllBills = [
    {id: "1", name: "1"},
    {id: "2", name: "2"},
    {id: "5", name: "5"},
    {id: "10", name: "10"},
    {id: "20", name: "20"},
    {id: "50", name: "50"},
    {id: "100", name: "100"},
    {id: "200", name: "200"}
]


export const ServiceCreate = (props) => {
    const classes = useStyles();
    return (<Create actions={<CreateActions/>} {...props}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                <Container className={classes.noPadding} fluid>
                    <Row>

                        <Col><TranslatableFields locales={Object.keys(GetAllowedLanguages())}
                                                 defaultLocale={Object.keys(GetAllowedLanguages())[0]}
                                                 label="ra.field.name" validate={required()}>
                            <TextInput className={classes.maxWidth} source="name"/>
                        </TranslatableFields>
                        </Col>
                    </Row>
                    <Card>
                        <Row className={classes.customPadding}>
                            <Col md={3}><NumberInput source="price"/></Col>
                            <Col md={3}><NumberInput source="rewardFixed"/></Col>
                            <Col md={3}><NumberInput source="rewardPercentage"/></Col>
                            <Col md={3}><BooleanInput className={classes.allowCC} source="allowCC"/></Col>

                        </Row>

                        <Row className={classes.customPadding}><Col><ReferenceInput className={classes.maxWidth}
                                                                                    label="ra.field.service_group"
                                                                                    source={'serviceGroup'}
                                                                                    reference={'service_groups'} {...props}>
                            <SelectInput optionText={SelectArrayInputNameFormatter}/>
                        </ReferenceInput></Col>
                        </Row>
                        <Row className={classes.customPadding}><Col><ReferenceInput className={classes.maxWidth}
                                                                                    label="ra.field.loyaltyProgram"
                                                                                    source={'loyaltyProgram'}
                                                                                    reference={'loyalty_programs'} {...props}>
                            <SelectInput optionText={'name'}/>
                        </ReferenceInput></Col></Row>
                        <Row className={classes.customPadding}> <Col><CompanyInput className={classes.maxWidth}
                                                                                   label="ra.field.company"/></Col></Row>
                        <Row className={classes.customPadding}>


                            <Col>

                                <Row>
                                    <Col>
                                        <SelectArrayInput choices={AllCoins} className={classes.maxWidth}
                                                          source="allowedCoins"/>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col><SelectArrayInput choices={AllBills} className={classes.maxWidth}
                                                           source="allowedBills"/></Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <ReferenceInput label="ra.field.taxGroup" source={'taxGroup'}
                                                        reference={'tax_groups'} {...props}>
                                            <SelectInput optionText={'name'}/>
                                        </ReferenceInput>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><NumberInput className={classes.dateWidth} source="sortOrder"/></Col>

                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col><DateTimeInput className={classes.dateWidth} source="startDate"/></Col>

                                </Row>
                                <Row>
                                    <Col><DateTimeInput className={classes.dateWidth} source="endDate"/></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                </Container>
            </SimpleForm>
        </Create>
    )
}


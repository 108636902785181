import {
    Create,
    Datagrid,
    Edit,
    List,
    NumberField,
    required,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from "react-admin";
import DateTimeField from "../Components/Fields/DateTimeField";
import Aside from "../Components/Aside";
import ListActions from "../Components/Toolbars/TopToolbars/ListActions";
import CreateActions from "../Components/Toolbars/TopToolbars/CreateActions";
import CreateToolbar from "../Components/Toolbars/BottomToolbars/CreateToolbar";
import CompanyInput from "../Components/Inputs/CompanyInput";
import ShowActions from "../Components/Toolbars/TopToolbars/ShowActions";
import CompanyField from "../Components/Fields/CompanyField";
import EditActions from "../Components/Toolbars/TopToolbars/EditActions";

const Title = ({record}) => {
    return <span>{record ? `#${record.uid}: ${record.name}` : ''}</span>;
};

export const UserGroupList = (props) => (
    <List
        title="ra.page.companies"
        sort={{ field: 'updatedAt', order: 'DESC' }}
        aside={<Aside filters={{
            id: 'live',
            name: 'live'
        }}/>} {...props} actions={<ListActions/>} >
        <Datagrid rowClick="edit">
            <NumberField label="#" source="originId" sortBy={'id'}/>
            <TextField label="ra.field.name" source={'name'}/>
            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>
        </Datagrid>
    </List>
)

export const UserGroupShow = (props) => (
    <Show actions={<ShowActions/>} title={<Title/>} {...props}>
        <SimpleShowLayout>

            <TextField label="ra.field.name" source={'name'}/>
            <TextField label="ra.field.company"/>
            <CompanyField/>

            <DateTimeField label="ra.field.createdAt" source={'createdAt'}/>
            <DateTimeField label="ra.field.updatedAt" source={'updatedAt'}/>

        </SimpleShowLayout>
    </Show>
)

export const UserGroupCreate = (props) => (
    <Create actions={<CreateActions/>} {...props}>
        <SimpleForm toolbar={<CreateToolbar/>}>
            <TextInput label="ra.field.name" source="name" validate={required()}/>
            <CompanyInput label="ra.field.company"/>
        </SimpleForm>
    </Create>
)

export const UserGroupEdit = (props) => (
    <Edit actions={<EditActions/>} title={<Title/>} {...props}>
        <SimpleForm>
            <TextInput label="ra.field.name" source="name"/>
            <CompanyInput label="ra.field.company"/>
        </SimpleForm>
    </Edit>
)

import * as React from 'react';
import {AppBar} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import UserMenu from "./UserMenu";
import LocaleSwitcher from "./LocaleSwitcher";

import Logo from './Logo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const HaircutAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<UserMenu/>}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo/>
            <span className={classes.spacer}/>
            <LocaleSwitcher/>
        </AppBar>
    );
};

export default HaircutAppBar;

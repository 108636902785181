import {ReferenceInput, required, SelectInput, TextInput} from 'react-admin'
import CompanyProps from "../../Theme/CompanyProps";
import {getUser} from '../../Providers/UserProvider';

const CompanyInput = props => {

    const companyProps = CompanyProps()

    if (!getUser().company) {
        return (
            <ReferenceInput validate={required()} label="ra.field.company" source={props.source ?? 'company'}
                            reference={props.reference ?? 'companies'} {...props}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
        )
    } else {
        return (
            <TextInput {...companyProps} disabled defaultValue={getUser().company} source={'company'}
                       reference={'companies'}/>
            // <ReferenceInput disabled allowEmpty={true} label="ra.field.company" source={props.source ?? 'company'}
            //                 reference={props.reference ?? 'companies'} {...props}>
            //     <SelectInput optionText="name"/>
            // </ReferenceInput>
        )
    }
}

export default CompanyInput
import {ChipField, Datagrid, List, ReferenceField, TextField} from "react-admin";
import Aside from "../../Components/Aside";
import React from "react";


export const WorkTimeReportList = (props) => {
    return (

        <List title="ra.reports.working_time_reports"
              {...props} sort={{ field: 'updatedAt', order: 'DESC' }}
              aside={<Aside filters={{
                  employee: {
                      'filter': 'resource',
                      'resource': "employees",
                      'optionText': 'name',
                      'multiple': false
                  },

              }}/>}>
            <Datagrid>

                <ReferenceField label="ra.field.employee" reference={"employees"} source={"employee"}>
                    <ChipField source={"name"}/>
                </ReferenceField>
                {/*<ReferenceField label="ra.field.terminal" reference={"terminals"} source={"terminal"}>*/}
                {/*    <ChipFieldMultiLang source={"name"}/>*/}
                {/*</ReferenceField>*/}
                <TextField label="ra.field.hours" source={"hours"}/>
                <TextField label="ra.field.minutes" source={"minutes"}/>


            </Datagrid>
        </List>
    )
}

import {
    BooleanField,
    ChipField,
    DateField,
    Edit,
    List,
    ReferenceArrayField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SingleFieldList,
    TextField,
    Pagination
} from 'react-admin';

import OrderListActions from "../Components/Toolbars/TopToolbars/OrderListActions";
import CompanyField from "../Components/Fields/CompanyField";
import ChipFieldMultiLang from "../Components/Fields/ChipFieldMultiLang"
import {i18nProvider} from "../Providers/i18nProvider";
import {TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import Aside from "../Components/Aside";
import DataGridWithTotals from "../Components/DataGridWithTotals"

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} {...props} />;

export const OrderList = (props) => (
    <List
        title="ra.page.orders"
        sort={{ field: 'incrementId', order: 'DESC' }}
        {...props} actions={<OrderListActions/>} aside={<Aside filters={{
        incrementId: 'live',
        cashless: 'boolean',
        'exists[employee]': 'employee_assigned',
        employee: {
            'filter': 'resource',
            'resource': "employees",
            'optionText': 'name',
            'multiple': true
        },
        customer: {
            'filter': 'resource',
            'resource': "customers",
            'optionText': 'telephone',
            'multiple': true
        },
        terminal: {
            'filter': 'resource',
            'resource': "terminals",
            'optionText': 'name',
            'multiple': true
        },
        registeredAt: 'dateRange',
    }}/>}
        pagination={<PostPagination />}
    >
        <DataGridWithTotals rowClick="edit" fieldsForSum={['subtotal', 'grandTotal']}>

            <TextField source="incrementId" label="#"/>


            <ReferenceField label="ra.field.terminal" source={"terminal"} reference={"terminals"}>
                <ChipFieldMultiLang source={"name"}/>
            </ReferenceField>

            <ReferenceArrayField label="ra.field.services" source={"services"} reference={"services"} link="show">
                <SingleFieldList>
                    <ChipFieldMultiLang source={"name"}/>
                </SingleFieldList>
            </ReferenceArrayField>


            <CompanyField source="company" label="ra.field.company"/>

            <ReferenceField label="ra.field.employee" source="employee" reference="employees" link="show">
                <ChipField source="name" emptyText="ra.field.undefined_"/>
            </ReferenceField>

            <ReferenceField label="ra.field.customer" source="customer" reference="customers" link="show">
                <ChipField source="telephone" emptyText="ra.field.undefined_"/>
            </ReferenceField>


            <TextField source="subtotal" label="ra.field.subtotal"/>

            <TextField source="grandTotal" label="ra.field.grandTotal"/>

            <TextField source="shortChange" label="ra.field.change"/>


            <BooleanField source="success" label="ra.field.success"/>

            <BooleanField source="cashless" label="ra.field.cashless"/>

            <TextField source="errors" label="ra.field.errors"/>

            <TextField source="transactionId" label="ra.field.transactionId"/>


            <DateField source="registeredAt" label="ra.field.registrationDate"/>


            <DateField source="updatedAt" label="ra.field.updatedAt"/>

            <DateField source="createdAt" label="ra.field.createdAt"/>

            <ShowButton/>

        </DataGridWithTotals>
    </List>
);

export const OrderShow = (props) => (
    <Show {...props}>
        <TableContainer className={"table-reflow"}>
            <TableBody>

                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell></TableCell>
                    <TableCell><TextField source="id" label="#"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.terminal")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <ReferenceField label="ra.field.terminal" source={"terminal"} reference={"terminals"}>
                            <ChipField source={"name"}/>
                        </ReferenceField>
                    </TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.service")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <ReferenceField label="ra.field.service" source="service" reference="services" link="show">
                            <ChipField source="name"/>
                        </ReferenceField></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.company")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <CompanyField source="company" label="ra.field.company"/></TableCell>


                </TableRow>

                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.employee")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <ReferenceField label="ra.field.employee" source="employee" reference="employees" link="show">
                            <ChipField source="name" emptyText="ra.field.undefined_"/>
                        </ReferenceField></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.customer")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <ReferenceField label="ra.field.customer" source="customer" reference="customers" link="show">
                            <ChipField source="email" emptyText="ra.field.undefined_"/>
                        </ReferenceField></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.grandTotal")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <TextField source="grandTotal" label="ra.field.grandTotal"/>
                    </TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.subtotal")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <TextField source="subtotal" label="ra.field.subtotal"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.change")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <TextField source="shortChange" label="ra.field.change"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.success")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <BooleanField source="success" label="ra.field.success"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.cashless")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <BooleanField source="cashless" label="ra.field.cashless"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.errors")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <TextField source="errors" label="ra.field.errors"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.transactionId")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <TextField source="transactionId" label="ra.field.transactionId"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.incrementId")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <TextField source="incrementId" label="ra.field.incrementId"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.registrationDate")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DateField source="registeredAt" label="ra.field.registrationDate"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.updatedAt")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DateField source="updatedAt" label="ra.field.updatedAt"/></TableCell>

                </TableRow>
                <TableRow>
                    <TableCell>{i18nProvider.translate("ra.field.createdAt")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DateField source="createdAt" label="ra.field.createdAt"/></TableCell>

                </TableRow>


            </TableBody>

        </TableContainer>
    </Show>

);


export const OrderEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>

            <ReferenceInput label="ra.field.employee" source="employee" reference="employees">
                <SelectInput optionText="name"/>
            </ReferenceInput>

            <ReferenceInput label="ra.field.customer" source="customer" reference="customers">
                <SelectInput optionText="telephone"/>
            </ReferenceInput>

            {/*<EmployeeCreate label="ra.field.employee" />*/}
            {/*<CustomerCreate label="ra.field.customer"/>*/}


        </SimpleForm>
    </Edit>
);

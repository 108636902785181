import {FilterList, FilterListItem} from 'react-admin'
import DoneAllIcon from '@material-ui/icons/DoneAll';

const BooleanFilter = (props) => {

    let values = {'true': {}, 'false': {}}
    values['true'][props.source] = true
    values['false'][props.source] = false

    return (
        <FilterList
            label={`ra.field.${props.source}`}
            icon={<DoneAllIcon/>}
        >
            <FilterListItem
                label="ra.field.boolean.true"
                value={values['true']}
            />
            <FilterListItem
                label="ra.field.boolean.false"
                value={values['false']}
            />
        </FilterList>

    )
};

export default BooleanFilter

import {
    Datagrid,
    List,
    ReferenceManyField,
    ReferenceField,
    TextField,
    Show,
    ChipField,
    ReferenceArrayField,
    SingleFieldList,
    BooleanField,
    DateField,
    ShowButton,
    Pagination,
    useListContext
} from "react-admin";
import Aside from "../../Components/Aside";
import ChipFieldMultiLang from "../../Components/Fields/ChipFieldMultiLang"
import CompanyField from "../../Components/Fields/CompanyField";
import React from "react";
import DataGridWithTotals from "../../Components/DataGridWithTotals";
import OrderListActions from "../../Components/Toolbars/TopToolbars/OrderListActions";



export const RevenueReportsList = (props) => {

    return (
        <List
            title="ra.field.revenue"
            {...props} sort={{ field: 'updatedAt', order: 'DESC' }}
            actions={<OrderListActions/>}
            pagination={<Pagination />}
            perPage={100}
            aside={<Aside filters={{
                date: 'dateRange',
                terminal: {
                    'filter': 'resource',
                    'resource': "terminals",
                    'optionText': 'name',
                    'multiple': true
                }
            }}/>}
            empty={false}
            >
            <Datagrid rowClick="show">
                <TextField label="ra.field.revenue" source={"revenue"}/>
                <TextField label="ra.field.paid_by_card" source={'byCard'}/>
                <TextField label="ra.field.without_employee" source={'emptyEmployee'}/>
                <TextField label="ra.field.count_orders" source={'count'}/>
                <ReferenceField label="ra.field.terminal" reference={"terminals"} source={"terminal"}>
                    <ChipFieldMultiLang source={"name"}/>
                </ReferenceField>
            </Datagrid >
        </List>
    )
}
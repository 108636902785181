const translation = {
    ra: {
        page: {
            orders: "Заказы",
            employees: "Сотрудники",
            serviceSets: "Наборы услуг",
            services: "Услуги",
            serviceGroups: "Группы услуг",
            taxGroups: "Налоговые группы",
            terminals: "Терминалы",
            loyaltyPrograms: "Программы лояльности",
            customers: "Клиенты",
            companies: "Компании",
            users: "Пользователи",
            locations: "Локации",
            list: "Список",
            languages: "Языки",
            userGroups: "Группы пользователей",
            loading: "Загрузка",
            dashboard: "Главная",
            notifications: "Сообщения",
            encashments: "Инкассации",
            revenue_orders: "Список заказов в обороте"

        },
        field: {
            id: "Номер",
            firstName: "Имя",
            lastName: "Фамилия",
            username: "Имя пользователя",
            email: "Емейл",
            boolean: {
                true: "Да",
                false: "Нет"
            },
            employeeAssigned: {
                true: "Привязанные",
                false: "Непривязанные"
            },
            active: "Активный",
            updatedAt: "Дата обновления",
            createdAt: "Дата создания",
            plainPassword: "Пароль",
            company: "Компания",
            userGroup: "Группа",
            price: "Цена",
            grandTotal: "Цена с уценкой",
            subtotal: "Цена",
            change: "Сдача",
            success: "Удачно",
            cashless: "Оплачено картой",
            statusDescription: "Статус",
            errors: "Ошибки",
            service: "Услуга",
            services: "Услуги",
            employee: "Сотрудник",
            terminal: "Терминал",
            customer: "Пользователь",
            registrationDate: "Дата регистрации",
            closedDate: "Дата закрытия",
            phone: "Телефон",
            barcode: "Баркод",
            password: "Пароль",
            name: "Имя",
            percentage: "Процентная ставка",
            workingTimeEnd: "Конец рабочего дня",
            workingTimeStart: "Начало рабочего дня",
            isArchived: "Архив",
            terminalNumber: "Номер терминала",
            pin: "Пин",
            address: "Адрес",
            softwareVersion: "Версия программы",
            city: "Город",
            country: "Страна",
            postcode: "Почтовый код",
            street: "Улица",
            serviceSet: "Набор услуг",
            location: "Локация",
            values: {
                all: "Все"
            },
            code: "Код языка",
            transactionId: "Номер транзакции",
            incrementId: "Номер заказа",
            undefined_: "Не назначен",
            taxGroup: "Налоговая группа",
            loyaltyProgram: "Программа лояльности",
            'service_group': "Группа услуг",
            cancel: "Отмена",
            ordersCount: "Кол-во заказов",
            ordersGrandTotals: "Сумма заказов",
            salary: "Зарплата",
            from: "От",
            to: "До",
            serviceId: "Номер услуги",
            serviceName: "Имя услуги",
            count: "Кол-во",
            title: "Заголовок",
            content: "Содержание",
            sum: "Сумма",
            hours: "Часы",
            minutes: "Минуты",
            cash: "Банкноты",
            coins: "Монеты",
            telephone: "Телефон",
            user_group: "Группа",
            denomination: "Номинал",
            count_orders: "Количество заказов",
            revenue: "Оборот",
            paid_by_card: "Оплачено картой",
            'exists[employee]': "Непривязанные к работнику",
            'without_employee': "Непривязанные к работнику",
            fullname: "Имя фамилия ( для панели )",
            loyalty: "Лояльность"
        },
        action: {
            show: "Просмотр",
            export: "Экспорт",
            delete: "Удалить",
            edit: "Изменить",
            create: "Добавить",
            undo: "Отменить"
        },
        date: {
            today: "Сегодня",
            this_week: "Текущая неделя",
            last_week: "Прошлая неделя",
            this_month: "Текущий месяц",
            last_month: "Прошлый месяц",
        },
        navigation: {
            page_rows_per_page: "Строк для просмотра",
            page_range_info: "Инф. о диапазоне",
            no_results: "No results ..."
        },
        notification: {
            deleted: "Удалено"
        },
        message: {
            loading: ""
        },
        buttons: {
            createServiceSet: "Создать набор услуг",
            createServiceGroup: "Создать группу услуг",
            createService: "Создать услугу"
        },
        menu: {
            reports: "Отчеты"
        },
        reports: {
            salary: "По зарплате",
            popularServices: "По услугам",
            encashments: "По инкассациям",
            working_time_reports: "По рабочему времени",
            monitoring: "Мониторинг",
            revenue_reports: "По обороту"
        },
        auth: {
            "username": "Емейл",
            "password": "Пароль",
            "sign_in": "Войти",
            "user_menu": "Меню",
            "logout": "Выйти"
        },
        validation: {
            required: "Обязательное поле"
        },
        button: {
            save_and_show: "Сохранить и посмотреть",
            save_and_add: "Сохранить и добавить"
        },
        text: {
            cash: "Купюры",
            coins: "Монеты"
        }
    }
}

export default translation

import React from "react";
import {Redirect, Route} from 'react-router-dom';
import {
    fetchHydra as baseFetchHydra,
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    useIntrospection
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./Providers/AuthProvider";
import {i18nProvider} from './Providers/i18nProvider';
import {Resource} from 'react-admin';


import Theme from "./Theme/Theme";
import Layout from "./Theme/Layout/Haircut"

import {UsersCreate, UsersEdit, UsersList, UsersShow} from './Entities/User';
import {EmployeeCreate, EmployeeEdit, EmployeeList, EmployeeShow} from './Entities/Employee';
import {CompanyCreate, CompanyEdit, CompanyList, CompanyShow} from "./Entities/Company";
import {TaxGroupCreate, TaxGroupEdit, TaxGroupList, TaxGroupShow} from "./Entities/TaxGroup";
import {LanguageCreate, LanguageEdit, LanguageList, LanguageShow} from "./Entities/Language";
import {
    LoyaltyProgramCreate,
    LoyaltyProgramEdit,
    LoyaltyProgramList,
    LoyaltyProgramShow
} from "./Entities/LoyaltyProgram";
import {CustomerCreate, CustomerEdit, CustomerList, CustomerShow} from "./Entities/Customer";
import {TerminalCreate, TerminalEdit, TerminalList, TerminalShow} from "./Entities/Terminal";
import {ServiceSetCreate, ServiceSetEdit, ServiceSetList, ServiceSetShow} from "./Entities/ServiceSet";
import {ServiceGroupCreate, ServiceGroupEdit, ServiceGroupList, ServiceGroupShow} from "./Entities/ServiceGroup";
import {ServiceCreate, ServiceEdit, ServiceList, ServiceShow} from "./Entities/Services";
import {OrderEdit, OrderList, OrderShow} from "./Entities/Orders";
import {SalaryList} from "./Entities/Report/Salary";
import {EncashmentsList} from "./Entities/Encashments";
import {PopularServiceList} from "./Entities/Report/PopularService";
import {NotificationsCreate, NotificationsEdit, NotificationsList, NotificationsShow} from "./Entities/Notifications";
import {EncashmentsReportList} from "./Entities/Report/EncashmentReports";
import {WorkTimeReportList} from "./Entities/Report/WorkTimeReports";
import {UserGroupCreate, UserGroupEdit, UserGroupList, UserGroupShow} from "./Entities/UserGroup"
import {Monitoring} from "./Entities/Monitoring";
import {RevenueReportsList} from "./Entities/Report/RevenueReports";

import {
    Business,
    Group,
    Language,
    LocalAtm,
    Loyalty,
    NotificationImportant,
    PeopleOutline,
    PermDeviceInformation,
    Storage,
    SupervisorAccount
} from '@material-ui/icons'
import 'bootstrap/dist/css/bootstrap.min.css';

import CustomRoutes from "./CustomRoutes";


const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    'Accept-Charset': 'UTF-8'
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login"/>;
};
const apiDocumentationParser = async (entrypoint) => {
    try {
        const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders});
        return {api};
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin}/>
                ],
            };
        }

        throw result;
    }
};
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);


export default () => (

    <HydraAdmin
        layout={Layout}
        theme={Theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        entrypoint={entrypoint}
        i18nProvider={i18nProvider}
        customRoutes={CustomRoutes}
    >
        {permissions => [
            <Resource name="users" list={UsersList} show={UsersShow} edit={UsersEdit} create={UsersCreate}
                      options={{label: "ra.page.users"}} icon={PeopleOutline}/>,

            <Resource name="employees" list={EmployeeList} show={EmployeeShow} edit={EmployeeEdit}
                      create={EmployeeCreate} options={{label: "ra.page.employees"}} icon={SupervisorAccount}/>,

            <Resource name="customers" list={CustomerList} show={CustomerShow} edit={CustomerEdit}
                      create={CustomerCreate} options={{label: "ra.page.customers"}} icon={Group}/>,

            <Resource name="tax_groups" list={TaxGroupList} show={TaxGroupShow} edit={TaxGroupEdit}
                      create={TaxGroupCreate} options={{label: "ra.page.taxGroups"}} icon={LocalAtm}/>,

            <Resource name="languages" list={LanguageList} show={LanguageShow} edit={LanguageEdit}
                      create={LanguageCreate} options={{label: "ra.page.languages"}} icon={Language}/>,

            <Resource name="terminals" list={TerminalList} show={TerminalShow} edit={TerminalEdit}
                      create={TerminalCreate} options={{label: "ra.page.terminals"}} icon={PermDeviceInformation}/>,

            <Resource name="loyalty_programs" list={LoyaltyProgramList} show={LoyaltyProgramShow}
                      edit={LoyaltyProgramEdit} create={LoyaltyProgramCreate}
                      options={{label: "ra.page.loyaltyPrograms"}} icon={Loyalty}/>,

            <Resource options={{label: "ra.page.serviceSets"}} name={"service_sets"} list={ServiceSetList}
                      edit={ServiceSetEdit} show={ServiceSetShow} create={ServiceSetCreate} icon={Storage}/>,

            <Resource options={{label: "ra.page.serviceGroups", hideFromMenu: true}} name={"service_groups"}
                      show={ServiceGroupShow}
                      list={ServiceGroupList} edit={ServiceGroupEdit} create={ServiceGroupCreate}/>,

            <Resource options={{label: "ra.page.services", hideFromMenu: true}} name={"services"} show={ServiceShow}
                      list={ServiceList} edit={ServiceEdit} create={ServiceCreate}/>,

            <Resource options={{label: "ra.page.orders"}} name={"orders"} show={OrderShow}
                      list={OrderList} edit={OrderEdit} icon={SupervisorAccount}/>,

            <Resource options={{label: "ra.page.notifications"}} name={"notifications"} show={NotificationsShow}
                      list={NotificationsList} edit={NotificationsEdit} create={NotificationsCreate}
                      icon={NotificationImportant}/>,

            <Resource options={{hideFromMenu: true}} name={"salaries"} list={SalaryList}/>,
            <Resource options={{hideFromMenu: true}} name={"popular_services"} list={PopularServiceList}/>,
            <Resource options={{hideFromMenu: true}} name={"encashment_reports"} list={EncashmentsReportList}/>,
            <Resource options={{hideFromMenu: true}} name={"working_time_reports"} list={WorkTimeReportList}/>,
            <Resource options={{hideFromMenu: true, label: "ra.page.encashments"}} name={"encashments"} list={EncashmentsList}/>,
            <Resource options={{hideFromMenu: true}} name={"terminal_states_latests"} list={Monitoring}/>,
            <Resource options={{hideFromMenu: true}} name={"revenue_reports"} list={RevenueReportsList}
            />,
            <Resource name="companies" options={{label: "ra.page.companies", hideFromMenu: (permissions.company !== null)}} list={CompanyList} show={CompanyShow}
                      edit={CompanyEdit}
                      create={CompanyCreate} icon={Business}/>,
            <Resource name="user_groups" options={{label: "ra.page.userGroups"}} list={UserGroupList}
                            create={UserGroupCreate}
                            show={UserGroupShow} edit={UserGroupEdit}/>,
        ]}
    </HydraAdmin>
);

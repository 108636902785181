const translation = {
    ra: {
        auth: {
            "username": "Email",
            "password": "Password",
            "sign_in": "Login",
            "user_menu": "User menu",
            "logout": "Logout"
        },
        validation: {
            required: "Required field"
        },
        page: {
            loading: "Loading",
            list: "List",
            dashboard: "Dashboard",
            users: "Administrators",
            userGroups: "User Groups",
            employees: "Employees",
            customers: "Clients",
            taxGroups: "Tax Groups",
            languages: "Languages",
            terminals: "Terminals",
            loyalty_programs: "Loyalty Programs",
            service_sets: "Services",
            companies: "Companies",
            create: "Create",
            loyaltyPrograms: "Loyalty Programs",
            serviceSets: "Services",
            notifications: "Notifications",
            encashments: "Encashments",
            orders: "Orders",
            count_orders: "Count of orders",
            revenue_orders: "List of orders in revenue"

        },
        message: {
            loading: "please wait"
        },
        date: {
            today: "Today",
            this_week: "This week",
            last_week: "Last week",
            this_month: "This month",
            last_month: "Last month"
        },
        action: {
            create: "Create",
            export: "Export",
            show: "Show",
            delete: "Delete",
            edit: "Edit",
            list: "List"
        },
        navigation: {
            page_range_info: "Range info",
            page_rows_per_page: "Rows per page",
            no_results: "No results ..."
        },
        buttons: {
            createServiceSet: "Create service set",
            createServiceGroup: "Create service group",
            createService: "Create service"
        },
        field: {
            name: "Name",
            createdAt: "Created at",
            updatedAt: "Updated at",
            address: "Address",
            active: "Is active",
            company: "Company",
            serviceSet: "Service set",
            email: "Email",
            firstName: "First name",
            lastName: "Last name",
            percentage: "Percentage",
            code: "Code",
            id: "#",
            boolean: {
                "true": "Yes",
                "false": "No"
            },
            taxGroup: "Tax Group",
            loyaltyProgram: "Loyalty Program",
            service_group: "Service Group",
            cancel: "Cancel",
            ordersCount: "Orders count",
            ordersGrandTotals: "Grand totals sum",
            salary: "Salary",
            from: "From",
            to: "To",
            serviceId: "Service id",
            serviceName: "Service name",
            count: "Count",
            title: "Title",
            content: "Content",
            services: "Services",
            sum: "Sum",
            hours: "Hours",
            minutes: "Minutes",
            cash: "Cash",
            coins: "Coins",
            telephone: "Telephone",
            user_group: "User group",
            denomination: "Denomination",
            paid_by_card: "Paid by card",
            without_employee: "Not assigned to employee",
            count_orders: "Orders count",
            empty_employee: "With no employee",
            fullname: "Full name",
            loyalty: "Loyalty count"
        },
        button: {
            'save_and_show': "Save and show",
            'save_and_add': "Save and add"
        },
        menu: {
            reports: "Reports"
        },
        reports: {
            salary: "Salary Report",
            popularServices: "Services popularity",
            encashments: "Encashment report",
            working_time_reports: "Work time reports",
            monitoring: "Monitoring",
            revenue_reports: "Revenue reports"
        },
        text: {
            cash: "Cash",
            coins: "Coins"
        }
    }
}

export default translation

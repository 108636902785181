import * as React from 'react';
import {Card as MuiCard, CardContent, withStyles} from '@material-ui/core';
import {FilterLiveSearch} from 'react-admin'
import BooleanFilter from "./Filter/BooleanFilter";
import DateFilter from "./Filter/DateFilter";
import CompanyFilter from "./Filter/CompanyFilter"
import ResourceFilter from "./Filter/ResourceFilter"
import {getUser} from "../../Providers/UserProvider"
import EmployeeAssignedFilter from "./Filter/EmployeeAssignedFilter";

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const FiltersMapping = {
    live: FilterLiveSearch,
    boolean: BooleanFilter,
    dateRange: DateFilter,
    resource: ResourceFilter,
    employee_assigned: EmployeeAssignedFilter
}

const Aside = (props) => (
    <Card>
        <CardContent>
            {
                Object.entries(props.filters).map((item) => {
                    let [key, value] = item
                    let isObject = false
                    let data = {}
                    if (typeof value === 'object' && typeof value.resource !== 'undefined') {
                        data = value
                        value = data.filter
                        isObject = true
                    }

                    if (isObject) {
                        if (typeof FiltersMapping[value] !== 'undefined') {
                            const FilterObject = FiltersMapping[value];
                            return (<FilterObject label={`ra.field.${key}`} source={key} {...data} />)
                        }
                    } else {
                        if (typeof FiltersMapping[value] !== 'undefined') {
                            const FilterObject = FiltersMapping[value];
                            return (<FilterObject label={`ra.field.${key}`} source={key}/>)
                        }
                    }

                })
            }
            {
                getUser().company === null
                    ? <CompanyFilter/>
                    : null
            }
        </CardContent>
    </Card>
)


export default Aside

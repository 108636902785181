import * as React from 'react';
import {Form} from 'react-final-form';
import {Box} from '@material-ui/core';
import {AutocompleteArrayInput, ReferenceArrayInput, SelectInput, useGetList, useListContext} from 'react-admin';
import Loading from "../../Loading"

const ResourceFilter = (props) => {
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter,
    } = useListContext();
    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        } else {
            hideFilter("main");
        }
    };

    const filterToQuote = (searchText) => {
        let result = {}
        result[props.optionText] = [searchText]
        return result
    }

    let items = [
        {name: "ra.field.values.all", id: null}
    ];

    const {data, ids, total, loading, loaded, error} = useGetList(props.resource);
    if (loading) {
        return <Loading/>;
    } else {
        Object.values(data).map((item) => {
            items.push(item)
        })
    }
    if (error) {
        return <p>ERROR</p>;
    }


    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <Box component="span" mr={12}>
                                {
                                    props.multiple ?
                                        <ReferenceArrayInput filterToQuery={filterToQuote} label={`ra.field.${props.source}`}
                                                             reference={props.resource} source={props.source}
                                                             onChange={handleSubmit}>
                                            <AutocompleteArrayInput optionText={props.optionText} optionValue={'id'}/>
                                        </ReferenceArrayInput> :
                                        <SelectInput source={props.source} choices={items} optionText={props.optionText}
                                                     optionValue="id" onChange={handleSubmit}/>
                                }
                            </Box>
                        </Box>
                    </form>
                )}
            </Form>
        </div>
    );
}

export default ResourceFilter;

import {Datagrid, List, ReferenceField, TextField} from "react-admin";
import Aside from "../../Components/Aside";
import ChipFieldMultiLang from "../../Components/Fields/ChipFieldMultiLang";


export const EncashmentsReportList = (props) => (
    <List
        title="ra.report.encashments"
        {...props} sort={{ field: 'updatedAt', order: 'DESC' }}
        aside={<Aside filters={{
            date: 'dateRange',
            terminal: {
                'filter': 'resource',
                'resource': "terminals",
                'optionText': 'name',
                'multiple': true
            }
        }}/>}>
        <Datagrid>
            {/*<TextField label="ra.field.id" source={"id"}/>*/}
            <ReferenceField label="ra.field.terminal" reference={"terminals"} source={"terminal"}>
                <ChipFieldMultiLang source={"name"}/>
            </ReferenceField>
            <TextField label="ra.field.sum" source={"sum"}/>

        </Datagrid>
    </List>
)

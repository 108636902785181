import {Datagrid, List, ReferenceField, TextField} from "react-admin";
import Aside from "../../Components/Aside";
import ChipFieldMultiLang from "../../Components/Fields/ChipFieldMultiLang"

export const PopularServiceList = (props) => {

    return (
        <List
            title="ra.reports.popularServices"
            {...props} sort={{ field: 'updatedAt', order: 'DESC' }}
            aside={<Aside filters={{
                date: 'dateRange',
                terminal: {
                    'filter': 'resource',
                    'resource': "terminals",
                    'optionText': 'name',
                    'multiple': true
                }
            }}/>}>
            <Datagrid>
                <ReferenceField label={"ra.field.serviceName"} reference={'services'} source={'serviceId'}>
                    <ChipFieldMultiLang source={'name'}/>
                </ReferenceField>
                <TextField label="ra.field.count" source={'count'}/>
            </Datagrid>
        </List>
    )
}

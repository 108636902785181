import {makeStyles} from '@material-ui/core/styles';
import logo from './images/logo.png'

const useStyles = makeStyles({
    logo: {
        maxWidth: "150px",
        width: "100%"
    },
})

const Logo = props => {
    const classes = useStyles();
    return (
        <img src={logo} alt="logo" className={classes.logo}/>
    )
}

export default Logo;
